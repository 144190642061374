import React from 'react';
import { Grid } from '@mui/material'
import phoneIcon from "../../images/svg/white/phone-volume-circle.svg"
import LocationOn from '@mui/icons-material/LocationOn';
import "./footer.css"


export default function FooterDireccion({clickOnDirection, footerData}) {
  return (
    <Grid container className={"containerfootherContact"}>
      <Grid item className={"divNumeros"} xs={12} md={4} lg={5}>
        {footerData?.phones?.map(phone => (
          <Grid key={phone.id} item xs={12} lg={6} style={{marginBottom: "10px"}} >
            <Grid item className={"itemNum"}>
              <img className={"imgPhoneSize"} alt="phones" src={phoneIcon} />
              <p className={"fontSizeContactData"}>  {phone.country} <a href={"tel://" + phone.phone_link} style={{borderBottom: "2px solid white", textDecoration: "none"}}>{phone.number}</a></p>
            </Grid>
          </Grid>
        ))}
        {/* <Grid item xs={12} lg={6} style={{marginBottom: "20px"}} >
          <Grid item className={"itemNum"}>
            <img className={"imgPhoneSize"} alt="phones" src={phone} />
            <p className={"fontSizeContactData"}>  USA <a href={"tel://+18556691306"} style={{borderBottom: "2px solid white", textDecoration: "none"}}>(855) 669-1306</a></p>
          </Grid>
        </Grid> */}
      </Grid>
      {footerData?.address &&
        <Grid item xs={12} md={8} lg={7} className={"addressContainer"}>
          <a href="#nourl" onClick={clickOnDirection} className={"enlaceDireccion"}>
            <LocationOn style={{ 
              /*width: "35px", height: "35px",     marginRight: "7px",*/
          marginRight: "5px",
          maxWidth: "35px",
          minWidth: "20px",
          fontSize: "clamp(20px, 4vw, 30px)",
          
          }}>Direccion</LocationOn>
            <p>{footerData?.address}</p>
          </a>
        </Grid>
      }
    </Grid>
  )
}