import React, { useState } from 'react'
type useSingleTypeProps = {
  name: string,
  population?: string[],
  onSuccess: (result:any) => void,
  onError: (error:any) => void,
  onComplete: () => void,
}

export const useSingleType = (props:useSingleTypeProps) => {
  const [singleType, setSingleType] = useState([])


  let queryString : string = "";
  if(props.population){
    queryString = props.population.map((populate, index) => `populate[${index}]=${populate}`).join("&");
  }else{
    queryString = "populate=*";
  }

  React.useEffect(() => {
    const url = `${process.env.API_STRAPI}${props.name}?${queryString}`

    fetch(url, {
      headers: {
        Authorization: 'Bearer ' + process.env.STRAPI_API_KEY
      }
    })
      .then(response => response.json())
      .then(response => response.data)
      .then(response => {
        if (response) {
        props.onSuccess(response)      
        }
      })
      .finally(props.onComplete)
      .catch(reason => {
        console.error("Error conectando con el servidor strapi: " + reason);
        props.onError(reason);
      })
  }, [])

  return singleType
}
