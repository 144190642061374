import React, { PropsWithChildren, useEffect } from "react"
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer";
import { usePageContext } from "../context";
import ModalLogin from "../components/modalLogin";
import { AlertaStack } from "../components/components";
import { Box, Button, Grid, IconButton, LinearProgress, Modal } from "@mui/material";

import ArrowIcon from '@mui/icons-material/ArrowUpwardRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from "gatsby";
import MapaDireccion from "../components/mapaDireccion";
import { ObtenerEsquemasCompra, ObtenerEsquemasCompraBody } from "../services/maverick";
import CookiesAlert from "../components/cookies-alert";
import { TimeoutLogic } from "../components/timeoutLogic";
import { useLocation } from '@reach/router';
import { usePageSenseScript, useScriptZoho } from "../hooks/use-script-zoho";
import { useScriptGoogle, useScriptGoogleTwo, useScriptGoogleThree } from "../hooks/use-google-tag";
import { useFbPixel, useFbPixelNoScript } from "../hooks/use-meta-pixel";
import { useLuckyorange } from "../hooks/use-luckyorange";
import CloseButton from "../components/close-button";
import useDynamicScripts from "../hooks/use-dynamic-scripts";
import { useSingleType } from "../hooks/use-single-type";

export default function MainLayout(props:PropsWithChildren) {
  const pageContext = usePageContext();9
  //const [openLogin, setOpenLogin] = React.useState(false);

  const [alertVariant, setAlertVariant] = React.useState("info");
  const [message, setMessage] = React.useState("");
  const [newAlert, setNewAlert] = React.useState(0);

  const [directionMapOpen, setDirectionMapOpen] = React.useState(false);
  const [currentLocation, setCurrentLocation] = React.useState(null);

  const [pageData, setPageData] = React.useState<any>()
  const [loaded, setLoaded] = React.useState<boolean>(false)
    
  const location = useLocation();

  useDynamicScripts();

  function openLoginModal() {
    document.getElementById("btnModalLogin")?.click();
  }

  function displayAlert(message:string, variant:string) {
    setAlertVariant(variant);
    setMessage(message);
    setNewAlert((value) => value + 1);
  }

  useEffect(() => {    
    if(["/lotes/", "/"].includes(location.pathname)) {
      pageContext?.setCopyrightPopUpOpen(true);
    }
    
    let body: ObtenerEsquemasCompraBody = {
      IdProducto: 1,
      Tipo: "A",
    };

    let esquemas:any = [];
    ObtenerEsquemasCompra(body)
    .then(response => response.json())
    .then(response => {
      if(response.Code === 200) {
        // esquemas.push(response.Data[0]);
        pageContext?.setEsquemasCompra(response.Data);
      }
    }).catch(reason => {
      console.log(reason);
    });
  }, []);

  useEffect(() => {
    // Define el método que deseas ejecutar en el layout
    (window as any).layoutMethod = (currentPath:string) => {
      if(["/terminos-condiciones/", "/lotes/"].includes(currentPath)) {
        
        // //Abrir el login si ha pasado cierto tiempo y no se ha iniciado sesión:
        // let notLoggedTimeOut = localStorage.getItem("notLoggedTimeOut");
        // if (notLoggedTimeOut) {
        //   setTimeout(() => {
        //     pageContext?.setCanCloseModal(false);
        //     setOpenLogin(true);
        //   }, 500);
        // } else {
        //   setTimeout(() => {
            //hay que validar si hay sesión y si esta esta seteada la variable en localstorage => notLoggedTimedOut
            let cliente: any = sessionStorage.getItem("cliente");
            if (!cliente) {
              //if (!notLoggedTimeOut) {
                //localStorage.setItem("notLoggedTimeOut", "1");
                pageContext?.setCanCloseModal(false);
                pageContext?.setOpenLoginModal(true);
              //}
            }
        //   }, 1000);
        // }
      // } else {
      //   pageContext?.setCanCloseModal(true);
      //   pageContext?.setOpenLoginModal(false);
      } else {
        pageContext?.setCanCloseModal(true);
        pageContext?.setOpenLoginModal(false);
      }
    };
    (window as any).layoutMethod(location.pathname);

    return () => {
      // Limpia el método cuando el componente se desmonte
      (window as any).layoutMethod = null;
    };
  }, []);
  useSingleType({
    name: "global",
    onSuccess(result) {
      setPageData(result);
    },
    onError(error) {
        console.log(error);            
    },
    onComplete() {
      setLoaded(true);
    },
  });

  React.useEffect(() => {
    if (pageContext?.openLoginModal) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [pageContext?.openLoginModal]);

  return (
      <>
        <Navbar id={"navHeader"} 
        session={pageContext?.session}
        setSession={pageContext?.setSession}
        signUp={() => {
          //setLoginView(false);
          //openLoginModal();
        }}
        logoImg={pageData?.logo}
        signIn={() => {
          pageContext?.setLoginView(true);
          openLoginModal();
        }} 
        />
        <div style={{height: 100}}></div>
        <main id="inicio" className={"mainPageWhite " + (pageContext?.openLoginModal ? "no-scroll" : "")}>
            <div style={{minHeight: "calc(100vh - 282px)"}}>
              { props.children }
            </div>
        </main>
        <Footer clickOnDirection={()=>{setDirectionMapOpen(true)}}/>
        {pageData?.whatsAppNumber && 
        <Grid className={"btnWhatsFloat"}>
          <a target={"_blank"} style={{ margin: "0" }} rel={"noreferrer"} href={`https://api.whatsapp.com/send?phone=${pageData?.whatsAppNumber}&text=${pageData?.whatsAppMessage}`}>
            <img className={"redIconWhats"} style={{ margin: "0" }} alt="whatsapp" src={"/static-files/PA/whatsappFloatCirculoVerde.png"} />
          </a>
        </Grid>
        }
        <Link to={"#inicio"}>
          <Button className={"btntoHeader"}>
            <ArrowIcon></ArrowIcon>
          </Button>
        </Link>
        {newAlert > 0 &&
          <AlertaStack message={message} newAlert={newAlert} variant={alertVariant}></AlertaStack>
        }
        <Modal
          open={directionMapOpen}
          onClose={() => { console.log("Modal cerrado") }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box id="boxModalDirection" sx={{
            width: 1024,
            maxWidth: "100%",
            height: 600,
            maxHeight: "100%",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            boxShadow: "0px 0px 25px black",
            borderRadius: 4,
          }}
            className="modalBox">
            <div style={{ position: "absolute", background: "white", zIndex: 20, right: currentLocation ? 0 : 50}}>
              <CloseButton onClick={() => { setDirectionMapOpen(false); }} white={true}/>
            </div>
            <MapaDireccion onCoordinatesLoaded={(coordinates: any) => {
              setCurrentLocation(coordinates);
            }} />
          </Box>
        </Modal>
        <ModalLogin
          setSession={pageContext?.setSession} 
          loginView={pageContext?.loginView} 
          setOpenLogin={pageContext?.setOpenLoginModal} 
          openLogin={pageContext?.openLoginModal} 
          onLogin={displayAlert}
          onClose={()=>{}}
          canCloseModal={pageContext?.canCloseModal}
        />
        <CookiesAlert messageText={pageData?.cookiesMessageAlert} btnText={pageData?.cookiesBtnAlert}/>
        {!process.env.TESTING &&      
          <TimeoutLogic session={pageContext?.session} setSession={pageContext?.setSession} />
        }
      </>
  )
}