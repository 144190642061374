import * as React from "react";
import logo from "../../images/logo.svg";

import 'animate.css';
import { Button } from "@mui/material";
import MenuSideBar from "./menuSideBar";
import { navigate } from "gatsby-link";
import Submenu, { Menu } from "./submenu";
import "./navbar.css";
import { Link } from "gatsby";
import { useSingleType } from "../../hooks/use-single-type";

type Props = {
  session:any,
  setSession:any,
  signUp: ()=>void,
  signIn: ()=>void,
  id: string,
  logoImg: any
}

const Navbar: React.FC<Props> = ({session, setSession, signUp, signIn, id, logoImg}) => {

    const [menu, setMenu] = React.useState<any>();

    useSingleType({
      name: "main-menu",
      population:["mainMenu.submenu.links","rightMenu.submenu.links"],
      onSuccess(result) {
          setMenu(result);
          
      },
      onError(error) {
          console.log(error);            
      },
      onComplete() {
      },
    });

    
  function logout() {
    sessionStorage.clear();
    localStorage.clear();
    setSession(false);
  }

  const [datosCliente, setDatosCliente] = React.useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    let DatosClienteSession = sessionStorage?.getItem("datosCliente");
    if (DatosClienteSession && DatosClienteSession !== "null") {
        setDatosCliente(JSON.parse(DatosClienteSession).DatosCliente);
    }
  }, [session]);

  return (
    <nav id={id}>
      <Link to="/">
        <img src={logoImg?.url ? logoImg?.url : logo } alt="Puntazul Logo" id="nav-logo" />
      </Link>
      <section>
        <menu style={{padding: 0}} className="mainMenu">
          {menu?.mainMenu?.map((menuItem: any, index:number) => (
            <div className="menuItem initialMenuItem" key={index}>
              {menuItem.submenu ? (
                  <>
                    <Button className="nav-button">{menuItem.name}</Button>
                    <Submenu menuItems={menuItem.submenu.links} />
                  </>
              ) : 
                menuItem.targetBlank ?
                  <a href={menuItem.path} target="_blank" ><Button className="nav-button" style={{height: "100%"}}>{menuItem.name}</Button></a>
                  :
                  <Button onClick={()=> {navigate(menuItem.path)}} className="nav-button">{menuItem.name}</Button>
              }              
            </div>
          ))}
          {menu?.mainMenu?.length > 4 &&
            <div className="menuItem masMenuItem">
              <Button className="nav-button">Más</Button>
              <Submenu menuItems={menu?.mainMenu?.slice(4)} />
            </div>
          }
        </menu> 
      </section>
      <MenuSideBar menuItems={menu?.mainMenu?.concat(menu?.rightMenu)} session={session} setSession={setSession} signIn={signIn} signUp={signUp} />
      <section className="leftMenu" style={{display: "flex", alignItems: "center"}}>
        <menu style={{padding: 0}}>
          {menu?.rightMenu?.map((menuItem:any, index:number) => (
            <div className="menuItem" key={index}>
              {
                menuItem.targetBlank ?
                  <a href={menuItem.path} target="_blank" ><Button className="nav-button" style={{height: "100%"}}>{menuItem.name}</Button></a>
                  :
                  <Button onClick={()=> {navigate(menuItem.path)}} className="nav-button">{menuItem.name}</Button>
              }
            </div>
          ))}
          { session ? (
              <Button className="nav-button login-button ariesButton" style={{alignSelf: "center"}} onClick={logout} variant="contained" disableElevation>Cerrar Sesión</Button>
          ) : (
              <Button className="nav-button login-button ariesButton" style={{alignSelf: "center"}} onClick={signIn} variant="contained" disableElevation>Login</Button>
          )}
        </menu>
      </section>
    </nav>
  )
}

export default Navbar