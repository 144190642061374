import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import { navigate }  from 'gatsby'
import PropTypes from 'prop-types';


import UserIcon from '@mui/icons-material/AccountCircle';
import LogOutIcon from '@mui/icons-material/ExitToApp';
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import { List, ListItemButton, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import '../../styles/menuSideBar.css';

function SubMenu({menuItem, handleClose = () => {}}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpenSubMenu = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton style={{padding: "8px 16px"}} onClick={toggleOpenSubMenu} >
          <ListItemText primary={menuItem.name} className='menuDrawerItems'/>
          {isOpen ? <IconExpandLess /> : <IconExpandMore />}
        </ListItemButton>            
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {menuItem?.links?.map((subMenuItem, i) =>
          subMenuItem.submenu ? 
            <SubMenu menuItem={subMenuItem.submenu} handleClose={handleClose}/>
          :
          <List component="div" disablePadding key={i}>
            {subMenuItem.targetBlank ?
              <a href={subMenuItem.path} target="_blank" style={{textDecoration: "none"}} aria-label='New Tab Link' rel="noreferrer">
                <ListItem disablePadding style={{backgroundColor: "#eef2f9"}}>
                  <ListItemButton style={{textDecoration: "none"}} onClick={()=> {handleClose();}}>
                    <ListItemText primary={subMenuItem.name} className="nav-button menuDrawerItems"/>
                  </ListItemButton>            
                </ListItem>
              </a>
              :
              <ListItem disablePadding style={{backgroundColor: "#eef2f9"}} key={i}>
                <ListItemButton onClick={()=> {navigate(subMenuItem.path); handleClose();}} style={{textDecoration: "none"}} key={i}>
                    <ListItemText className="nav-button menuDrawerItems">{ subMenuItem.name }</ListItemText>
                </ListItemButton> 
              </ListItem>
            }
          </List>
        )}
      </Collapse>
    </>
  );
}
SubMenu.propTypes = {
  menuItem: PropTypes.any.isRequired,
  handleClose: PropTypes.func
};

function MenuSideBarItem({menuItem, handleClose = () => {}}) {
  return (
    menuItem.targetBlank ?
      <a href={menuItem.path} target="_blank" style={{textDecoration: "none"}} aria-label='New Tab Link' rel="noreferrer">
        <ListItem disablePadding>
          <ListItemButton style={{padding: "8px 16px"}} onClick={()=> {handleClose();}}>
            <ListItemText primary={menuItem.name} className='menuDrawerItems'/>
          </ListItemButton>            
        </ListItem>
      </a>
    :
      <ListItem disablePadding>
        <ListItemButton style={{padding: "8px 16px"}} onClick={()=> {navigate(menuItem.path); handleClose();}} >
          <ListItemText primary={menuItem.name} className='menuDrawerItems'/>
        </ListItemButton>            
      </ListItem>
  )
}
MenuSideBarItem.propTypes = {
  menuItem: PropTypes.any.isRequired,
  handleClose: PropTypes.func
};

export default function MenuSideBar({ menuItems, setSession, session, signIn, signUp }) {

  const [state, setState] = React.useState({
    left: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    // if (open === true) {
      setState({ ...state, [anchor]: open });
    // }
  };

  const handleClose = () => {

    setState({ ...state, right: false });

  };

  const logout = () => {

    window.sessionStorage.removeItem("cliente");
    window.sessionStorage.removeItem("nombre");
    window.sessionStorage.removeItem("datosCliente");
    localStorage.clear();
    setSession(false)

  }


  const list = (anchor) => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {menuItems?.map((menuItem, index) => (
          <React.Fragment key={index}>            
            {menuItem.submenu ? 
              <SubMenu menuItem={menuItem.submenu} handleClose={handleClose}/>
              :
              <MenuSideBarItem menuItem={menuItem} handleClose={handleClose}/>
            }
          </React.Fragment>
        ))}

        {session ?
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => {logout(); handleClose();}}>
                <ListItemIcon>
                  <LogOutIcon/>
                </ListItemIcon>
                <ListItemText primary="Cerrar Sesión" className='menuDrawerItems'/>
              </ListItemButton>
            </ListItem>
          </>
          :          
          <>
            <ListItem disablePadding>
              <ListItemButton style={{padding: "8px 16px"}} onClick={() => {signIn(); handleClose();}}>
                <ListItemIcon>
                  <UserIcon/>
                </ListItemIcon>
                <ListItemText primary="Iniciar Sesión" className='menuDrawerItems'/>
              </ListItemButton>
            </ListItem>
          </>
        }        
      </List>
    </Box>
  );

  const [datosCliente, setDatosCliente] = React.useState({});
  React.useEffect(() => {
    let DatosClienteSession = sessionStorage?.getItem("datosCliente");
    if (DatosClienteSession && DatosClienteSession !== "null") {
        setDatosCliente(JSON.parse(DatosClienteSession).DatosCliente);
    }
  }, [session]);

  return (
    <div key={"Grid-return-SideMenu"} className={"menuSideBar"} >
      {['right']?.map((anchor) => (
        <React.Fragment key={anchor}>
          <button className="account_badge" onClick={toggleDrawer(anchor, true)}>
            { (session && datosCliente.Nombre) &&
              <span className="acount_badge_name">
                { datosCliente.Nombre + " " + datosCliente.ApellidoPaterno }
              </span>
            }
            <span className="account_badge_button" aria-label="delete">
              {/* session ? <PersonIcon /> : <MenuIcon /> */}
              <MenuIcon /> 
            </span>
          </button>
          <SwipeableDrawer className={"menuSideBar"}
            anchor={'right'}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}




